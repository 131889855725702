<template>
  <div v-if="['admin', 'headmanager', 'callcenter', 'manager', 'sop'].includes($store.state.me.role)" class="lsg-cabinet">
    <div class="main__header">
      <div class="main__header__item left"></div>
      <div class="main__header__item">
        <h1>{{ title }}</h1>
      </div>
      <div class="main__header__item right"></div>
    </div>
    <div class="main__content">
      <form class="lk" :class="{ 'form-sign': active }" autocomplete="off" @submit.prevent="sign">
        <div class="lk__inputs" style="max-width: 560px">
          <div class="lk__field">
            <label for="companyInn">Компания</label>
            <CompanyInput
              v-if="!$route.query.inn"
              columnName="name"
              :currentValue="activeCompany.name"
              filterName="q"
              placeholder="Компания/ИНН"
              @change="onCompanyChange"
            />
            <input v-else type="text" disabled :value="activeCompany.name">
          </div>
          <div class="lk__field">
            <label for="name">ФИО</label>
            <input id="name" v-model.trim="fields.name" type="text" required>
          </div>
          <div class="lk__field">
            <label for="birthday">Дата рождения</label>
            <input id="birthday" v-model="fields.birthday" type="date" required>
          </div>
          <div class="lk__field">
            <label for="birthplace">Место рождения</label>
            <input id="birthplace" v-model.trim="fields.birthplace" type="text" required>
          </div>
          <div class="lk__field">
            <label for="passport">Серия и номер паспорта (без пробелов)</label>
            <input id="passport" v-model.trim="fields.passport" type="test" required>
          </div>
          <div class="lk__field">
            <label for="passportPlace">Место выдачи паспорта</label>
            <input id="passportPlace" v-model.trim="fields.passport_place" type="text" required>
          </div>
          <div class="lk__field">
            <label for="passportPlace">Дата выдачи паспорта</label>
            <input id="passportDate" v-model="fields.passport_date" type="date" required>
          </div>
          <div class="lk__field">
            <label for="passportCode">Код подразделения</label>
            <input id="passportCode" v-model.trim="fields.passport_code" type="text" required>
          </div>
          <div class="lk__field">
            <label for="userAddress">Адрес</label>
            <input id="userAddress" v-model.trim="fields.user_address" type="text" required>
          </div>
          <div class="lk__field">
            <label for="userInn">ИНН представителя</label>
            <input id="userInn" v-model="fields.user_inn" type="text" required>
          </div>
          <div class="lk__field">
            <label for="userPhone">Номер телефона (*на этот номер будет выслан код подтверждения)</label>
            <input id="userPhone" v-model.number="fields.user_phone" type="text" required>
          </div>
          <div class="lk__field">
            <label for="passportCode">Код из СМС</label>
            <input id="passportCode" v-model.number="fields.sms_key" type="text">
          </div>
          <div v-if="!sendingData" class="buttons">
            <button type="button" class="btn" @click="sendSms">Отправить СМС</button>
            <button type="submit" :disabled="disabled" class="btn">Подписать</button>
          </div>
          <div v-else class="buttons">
            <button disabled class="btn btn--gray">Отправить СМС</button>
            <button disabled class="btn btn--gray">Подписать</button>
          </div>
        </div>
        <div class="lk__back">
          <CompanyBack/>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import CompanyBack from '@/components/svg/CompanyBack'
import CompanyInput from '@/components/ui/GraphInputs/CompanyInput'
import ADD_ESIGNATURE from '@/graphql/mutations/AddEsignature.gql'
import ADD_NOTE from '@/graphql/mutations/AddNote.gql'
import axios from 'axios'
import GraphQLHelper from '@/helpers/GraphQLHelper'

const _graphQlHelper = new GraphQLHelper()

export default {
  name: 'Sign',
  components: {
    // MinusIco,
    // PlusIco,
    // TagsInput,
    // DownIcon,
    CompanyInput,
    CompanyBack
  },
  data () {
    return {
      title: 'Онлайн подписание',
      active: false,
      disabled: true,
      sendingData: false,
      fields: {
        company_inn: '',
        name: '',
        birthday: null,
        birthplace: '',
        user_address: '',
        user_inn: '',
        passport: '',
        passport_place: '',
        passport_code: '',
        passport_date: null,
        user_phone: '',
        sms_key: ''
      },
      activeCompany: {},
      smsCode: ''
    }
  },
  created () {
    if (this.$route.query.inn) {
      this.fields.company_inn = this.$route.query.inn
      this.activeCompany = {
        name: this.$route.query.name,
        inn: this.$route.query.inn,
        _id: this.$route.query.id
      }
    }
  },
  methods: {
    onCompanyChange (item) {
      if (item) {
        this.activeCompany = item
        this.fields.company_inn = item.inn
      }
    },
    sendSms () {
      this.active = true
      if (!this.fields.company_inn) {
        this.$notify({
          group: 'lsg-notify',
          text: 'Поле "Компания" заполнено некорректно'
        })
      }
      Object.keys(this.fields).forEach(elem => {
        if (elem !== 'sms_key') {
          if (!this.fields[elem] || this.fields[elem] === '') {
            this.$notify({
              group: 'lsg-notify',
              text: 'Заполнены не все поля'
            })
            throw new Error('Заполнены не все поля')
          }
        }
      })
      const smsCode = Math.floor(Math.random() * (9999 - 1000 + 1)) + 1000
      if (!this.fields.user_phone) {
        this.$notify({
          group: 'lsg-notify',
          text: 'Не введен номер телефона'
        })
        throw new Error('Не введен номер телефона')
      }
      this.smsCode = smsCode
      const text = `Vash code: ${smsCode} dlya podpisania Soglasheniya ob ispolsovanii prostoi elektronoy podpisi https://leasing-trade.ru/documents/soglashenie.pdf`
      const BASE_URL = `${process.env.VUE_APP_HTTP}`
      const url = `${BASE_URL}/sms`
      axios.post(url, { text: text, phone: this.fields.user_phone }).then(res => {
        if (res.status === 200) {
          this.smsCheck = true
          this.$notify({
            group: 'lsg-notify',
            text: `Отправлен СМС с кодом на номер ${this.fields.user_phone}`
          })
          this.disabled = false
          return true
        } else return false
      })
    },
    addNoteInCompany (uuid) {
      this.$apollo.mutate({
        mutation: ADD_NOTE,
        variables: {
          input: {
            text: `
              Добавлена электронная подпись.
              ФИО: ${this.fields.name}.
              Дата рождения: ${this.fields.birthday}.
              Место рождения: ${this.fields.birthplace}.
              Адрес: ${this.fields.user_address}.
              ИНН: ${this.fields.user_inn}.
              Серия и номер паспорта: ${this.fields.passport}.
              Место выдачи паспорта: ${this.fields.passport_place}.
              Дата выдачи паспорта: ${this.fields.passport_date}.
              Код подразделения: ${this.fields.passport_code}.
              Номер телефона: ${this.fields.user_phone}.
              Номер для отслеживания: ${uuid}.
              Дата электронного согласия: ${new Date().toLocaleString()}
            `,
            element_id: this.activeCompany._id,
            element_type: 'Company',
            is_editable: false,
            note_type: 'Common'
          }
        },
        update: (store, { data }) => {
          if (data) {
            this.$notify({
              group: 'lsg-notify',
              text: 'Комментарий в компании добавлен'
            })
            this.$router.push({ path: `/companies/${this.activeCompany._id}` })
            this.$router.go()
          }
        }
      }).catch(error => {
        this.$notify({
          group: 'lsg-notify',
          clean: true
        })
        _graphQlHelper.graphQLErrorMessages(error).forEach(item => {
          this.$notify({
            group: 'lsg-notify',
            text: item
          })
        })
      })
    },
    sign () {
      this.active = true
      this.sendingData = true
      if (this.smsCode !== this.fields.sms_key) {
        this.$notify({
          group: 'lsg-notify',
          text: 'Неверный СМС код'
        })
        this.sendingData = false
        throw new Error('Неверный СМС код')
      }
      Object.values(this.fields).forEach(elem => {
        if (!elem || elem === '') {
          this.$notify({
            group: 'lsg-notify',
            text: 'Заполнены не все поля'
          })
          this.sendingData = false
          throw new Error('Заполнены не все поля')
        }
      })
      const date = new Date().toISOString()
      this.$notify({
        group: 'lsg-notify',
        text: 'Отправка данных...',
        duration: -1
      })
      this.$apollo.mutate({
        mutation: ADD_ESIGNATURE,
        variables: {
          input: {
            name: this.fields.name,
            birthday: new Date(this.fields.birthday).toISOString(),
            birthplace: this.fields.birthplace,
            user_address: this.fields.user_address,
            user_inn: this.fields.user_inn.toString(),
            passport_date: new Date(this.fields.passport_date).toISOString(),
            passport: this.fields.passport.toString(),
            passport_place: this.fields.passport_place,
            passport_code: this.fields.passport_code,
            user_phone: this.fields.user_phone.toString(),
            company_inn: this.fields.company_inn.toString(),
            sms_key: this.fields.sms_key.toString(),
            sms_date: date,
            sms_phone: this.fields.user_phone.toString()
          }
        },
        update: (store, { data }) => {
          if (data) {
            this.$notify({
              group: 'lsg-notify',
              text: 'Электронная подпись успешно добавлена'
            })
            this.addNoteInCompany(data.addEsignature.esignature_id)
          }
        }
      }).catch(error => {
        this.$notify({
          group: 'lsg-notify',
          clean: true
        })
        _graphQlHelper.graphQLErrorMessages(error).forEach(item => {
          this.$notify({
            group: 'lsg-notify',
            text: item
          })
          this.$notify({
            group: 'lsg-notify-support',
            title: 'Сообщить в поддержку',
            text: `Нажав на это сообщение, вы отправите отчёт об ошибке в тех.поддержку`,
            type: 'info',
            closeOnClick: true,
            data: {
              info: {
                project: 'Личный кабинет Leasing-trade',
                name: this.$store.state.me.name,
                email: this.$store.state.me.email,
                group_id: this.$store.state.me.group.id,
                role: this.$store.state.me.role,
                localTime: new Date(),
                system: navigator.userAgent,
                url: window.location.href,
                window: `${window.window.screen.availWidth}px ${window.window.screen.availHeight}px`,
                error: item,
                comment: 'Ошибка при создании онлайн согласия'
              },
              style: 'cursor: pointer'
            }
          })
        })
        this.sendingData = false
      })
    }
  }
}
</script>

<style lang="stylus" scoped>
  .buttons {
    display flex
    flex-direction row
    flex-wrap wrap
    justify-content space-between
    width 100%

    > button[type="submit"]:disabled {
      background #979797 !important
      border none
      cursor auto
    }
  }
  .form-sign > .lk__inputs > .lk__field {
    input {
      &:invalid {
        border 2px dashed red !important
      }
      // &:valid {
      //   border 2px solid $anothergreen !important
      // }
    }
  }
</style>
